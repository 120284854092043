@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
p {
  font-weight: 200;
}

.slick-dots li.slick-active button:before {
  color: #eab43e !important;
  font-size: 20px;
}

::-webkit-scrollbar {
  width: 6px; /* Width of vertical scrollbar */
  max-height: 10px; /* Height of horizontal scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 10px;
}

/* Scrollbar thumb hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



